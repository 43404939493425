module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"juliannasielski","defaultLang":"en-us","path":"/preview","previews":true,"pages":[{"type":"Post","match":"/post/:uid","previewPath":"/post","component":"/opt/build/repo/src/templates/post.jsx","sortBy":"meta_lastPublicationDate_ASC"},{"type":"Project","match":"/project/:uid","previewPath":"/project","component":"/opt/build/repo/src/templates/project.jsx","sortBy":"meta_firstPublicationDate_ASC"}],"sharpKeys":[{},"profilepic"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-prismic-starter-prist","short_name":"prist","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/JN.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"57ee80c69a6de9aa9fc5a29ff82f2822"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
